document.addEventListener("turbolinks:load", function() {
  var countriesWithState = ['US', 'CA', 'ES', 'IT', 'AU']
  changeCountry = function() {
    field = $('#shipping_country');
    if (jQuery.inArray(field.val(), countriesWithState) >= 0){
      $(".col--shipping_state").show();
      $.get('/states/by_country.js?select_id=shipping_state&country_code=' + field.val());
    } else {
      $(".col--shipping_state").hide();
      $("#shipping_state").val("");
    }
    $("#shipping_country_name").val($("#shipping_country option:selected").text())
  }

  // Exibe/oculta campos ao carregar.
  field = $('#shipping_country');
  if (jQuery.inArray(field.val(), countriesWithState) >= 0){
    $(".col--shipping_state").show();
  } else {
    $(".col--shipping_state").hide();
    $("#shipping_state").val("");
  }

  $('#shipping_country').change(changeCountry);

  $('#shipping_state').change(function() {
    $("#shipping_state_name").val($("#shipping_state option:selected").text())
  })

  $('.international-delivery-form .shipping_prices input').change(function() {
    var input = $(this);
    if (input.prop('checked')) {
      var delivery_method = input.val();
      $("#international_delivery_method").val(delivery_method);
      $('.cart_total b').html(input.data('cart_total'));
    }
  });
});


// DEPRECATED: Não está sendo usado desde a mudança de layout.
// $(document).ready(function () {
// 
//   toggleStateSelect('.international-delivery-form');
//   $(".alert-invalid_pod_package_id").hide();
//   $(".international-delivery-form .alert-tax").hide();
//   $(".international-delivery-form .return-tax").hide();
//   $('#international_shipping_loading').hide();
//   $('.international-delivery-form .shipping_prices').click(setDeliveryMethod);
//   $(".address-field").change(resetInternationalDeliveryForm);
// 
//   let mail_cost = 0;
//   let express_cost = 0;
//   let total_cost = 0;
//   let delivery_method = null;
// 
//   function fillIn(arr, data){
//     $(data).each(function(){
//       arr.push({id: $(this)[0].id, value: $(this)[0].value});
//     });
//     arr.sort(function(a,b) { return ((a.value < b.value) ? -1 : ((a.value > b.value) ? 1 : 0))});
//   }
// 
//   function toggleStateSelect(form){
//     if($(form + " #shipping_country").val() == "US"){
//       $(form + " .state-select").show();
//     } else {
//       $(form + " .state-select #shipping_state_name").val("");
//       $(form + " .state-select #shipping_state").val("");
//       $(form + " .state-select").hide();
//     }
//   }
// 
//   $.getJSON( "/javascripts/countries.json", function( data ) {
//     var countries = [];
//     fillIn(countries, data);
// 
//     $(countries).each(function(){
//       var item = $(this)[0];
//       if (item.id == $(".international-delivery-form #shipping_country" ).val())
//         $( ".international-delivery-form #shipping_country_name" ).val(item.value);
//     });
// 
//     $( ".international-delivery-form #shipping_country_name" ).autocomplete({ source: countries,
//       select: function( event, ui ) {
//         $(".international-delivery-form #shipping_country_name").val(ui.item.value);
//         $(".international-delivery-form #shipping_country").val(ui.item.id);
//         toggleStateSelect('.international-delivery-form');
//         return false;
//       }
//     });
//     $('.international-delivery-form #shipping_country_name').attr('autocomplete', 'new-password');
//   });
// 
//   $.getJSON( "/javascripts/states.json", function( data ) {
//     var states = [];
// 
//     fillIn(states, data);
// 
//     $(states).each(function(){
//       var item = $(this)[0];
//       if (item.id == $(".international-delivery-form #shipping_state" ).val())
//         $( ".international-delivery-form #shipping_state_name" ).val(item.value);
//     });
// 
//     $( ".international-delivery-form #shipping_state_name" ).autocomplete({ source: states,
//       select: function( event, ui ) {
//         $(".international-delivery-form #shipping_state_name").val(ui.item.value);
//         $(".international-delivery-form #shipping_state").val(ui.item.id);
//         return false;
//       }
//     });
//     $('.international-delivery-form #shipping_state_name').attr('autocomplete', 'new-password');
//   });
// 
//   $('.international-delivery-form').ajaxStart( function() {
//     $(".alert-invalid_pod_package_id").hide();
//     $(".alert-tax").hide();
//     $('#international_shipping_loading').show();
//   }).ajaxComplete( function() {
//     $('#international_shipping_loading').hide();
//   }).on('ajax:success', function(e, data, status, xhr) {
//     total_cost = parseFloat(data.cart_total_without_shipping);
// 
//     if ( data.mail_cost && !isNaN(data.mail_cost)){
//       mail_cost = parseFloat(data.mail_cost);
//       $('.mail').text('US$ ' + data.mail_cost);
//       $(".shipping_prices").first().show();
//     }
//     if ( data.express_cost && !isNaN(data.express_cost) ){
//       express_cost = parseFloat(data.express_cost);
//       $('.express').text('US$ ' + data.express_cost);
//       $(".shipping_prices").last().show();
//     };
// 
//     let default_method = isNaN(data.mail_cost) ? 'express' : 'mail';
//     if (default_method == 'mail')
//       $('.shipping_prices').first().trigger('click');
//     else if (default_method == 'express')
//       $('.shipping_prices').last().trigger('click');
// 
//   }).on('ajax:error', function(e, data, status, xhr) {
//     data = JSON.parse(data.responseText)
//     if (data.message == "Pod_package_id invalido") {
//       $(".alert-invalid_pod_package_id").show();
//     } else {
//       $(".alert-tax").show();
//     };
//     resetInternationalDeliveryForm();
//   });
// 
//   function updateSubtotal(){
//     let delivery_cost = (delivery_method === "MAIL" ? mail_cost : express_cost);
//     $(".sub_total_text").html("Subtotal: US$ " + parseFloat(total_cost + delivery_cost).toFixed(2));
//   }
// 
//   function setDeliveryMethod(){
//     let input = $(this).find("input");
//     input.prop("checked", "checked");
//     if(input.prop('checked')) {
//       delivery_method = input.val();
//       $("#international_delivery_method").val(delivery_method);
//     }
//     updateSubtotal();
//   }
// 
//   function resetInternationalDeliveryForm(){
//     $(".shipping_prices").hide();
//     $('#international_delivery_method_mail').prop('checked', false);
//     $('#international_delivery_method_express').prop('checked', false);
//     mail_cost, express_cost = 0, 0;
//     delivery_method = null;
//   }
// });
