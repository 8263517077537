/////////////////////////////////////////////////////////
// Dependências

// jQuery Mask Plugin v1.14.16
// github.com/igorescobar/jQuery-Mask-Plugin
// TODO: IMPORTAR O MODULO PELO WEBPACKER
var $jscomp = $jscomp || {}; $jscomp.scope = {}; $jscomp.findInternal = function (a, n, f) { a instanceof String && (a = String(a)); for (var p = a.length, k = 0; k < p; k++) { var b = a[k]; if (n.call(f, b, k, a)) return { i: k, v: b } } return { i: -1, v: void 0 } }; $jscomp.ASSUME_ES5 = !1; $jscomp.ASSUME_NO_NATIVE_MAP = !1; $jscomp.ASSUME_NO_NATIVE_SET = !1; $jscomp.SIMPLE_FROUND_POLYFILL = !1;
$jscomp.defineProperty = $jscomp.ASSUME_ES5 || "function" == typeof Object.defineProperties ? Object.defineProperty : function (a, n, f) { a != Array.prototype && a != Object.prototype && (a[n] = f.value) }; $jscomp.getGlobal = function (a) { return "undefined" != typeof window && window === a ? a : "undefined" != typeof global && null != global ? global : a }; $jscomp.global = $jscomp.getGlobal(this);
$jscomp.polyfill = function (a, n, f, p) { if (n) { f = $jscomp.global; a = a.split("."); for (p = 0; p < a.length - 1; p++) { var k = a[p]; k in f || (f[k] = {}); f = f[k] } a = a[a.length - 1]; p = f[a]; n = n(p); n != p && null != n && $jscomp.defineProperty(f, a, { configurable: !0, writable: !0, value: n }) } }; $jscomp.polyfill("Array.prototype.find", function (a) { return a ? a : function (a, f) { return $jscomp.findInternal(this, a, f).v } }, "es6", "es3");
(function (a, n, f) { "function" === typeof define && define.amd ? define(["jquery"], a) : "object" === typeof exports && "undefined" === typeof Meteor ? module.exports = a(require("jquery")) : a(n || f) })(function (a) {
  var n = function (b, d, e) {
    var c = {
      invalid: [], getCaret: function () { try { var a = 0, r = b.get(0), h = document.selection, d = r.selectionStart; if (h && -1 === navigator.appVersion.indexOf("MSIE 10")) { var e = h.createRange(); e.moveStart("character", -c.val().length); a = e.text.length } else if (d || "0" === d) a = d; return a } catch (C) { } }, setCaret: function (a) {
        try {
          if (b.is(":focus")) {
            var c =
              b.get(0); if (c.setSelectionRange) c.setSelectionRange(a, a); else { var g = c.createTextRange(); g.collapse(!0); g.moveEnd("character", a); g.moveStart("character", a); g.select() }
          }
        } catch (B) { }
      }, events: function () {
        b.on("keydown.mask", function (a) { b.data("mask-keycode", a.keyCode || a.which); b.data("mask-previus-value", b.val()); b.data("mask-previus-caret-pos", c.getCaret()); c.maskDigitPosMapOld = c.maskDigitPosMap }).on(a.jMaskGlobals.useInput ? "input.mask" : "keyup.mask", c.behaviour).on("paste.mask drop.mask", function () {
          setTimeout(function () { b.keydown().keyup() },
            100)
        }).on("change.mask", function () { b.data("changed", !0) }).on("blur.mask", function () { f === c.val() || b.data("changed") || b.trigger("change"); b.data("changed", !1) }).on("blur.mask", function () { f = c.val() }).on("focus.mask", function (b) { !0 === e.selectOnFocus && a(b.target).select() }).on("focusout.mask", function () { e.clearIfNotMatch && !k.test(c.val()) && c.val("") })
      }, getRegexMask: function () {
        for (var a = [], b, c, e, t, f = 0; f < d.length; f++)(b = l.translation[d.charAt(f)]) ? (c = b.pattern.toString().replace(/.{1}$|^.{1}/g, ""), e = b.optional,
          (b = b.recursive) ? (a.push(d.charAt(f)), t = { digit: d.charAt(f), pattern: c }) : a.push(e || b ? c + "?" : c)) : a.push(d.charAt(f).replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")); a = a.join(""); t && (a = a.replace(new RegExp("(" + t.digit + "(.*" + t.digit + ")?)"), "($1)?").replace(new RegExp(t.digit, "g"), t.pattern)); return new RegExp(a)
      }, destroyEvents: function () { b.off("input keydown keyup paste drop blur focusout ".split(" ").join(".mask ")) }, val: function (a) {
        var c = b.is("input") ? "val" : "text"; if (0 < arguments.length) {
          if (b[c]() !== a) b[c](a);
          c = b
        } else c = b[c](); return c
      }, calculateCaretPosition: function (a) { var d = c.getMasked(), h = c.getCaret(); if (a !== d) { var e = b.data("mask-previus-caret-pos") || 0; d = d.length; var g = a.length, f = a = 0, l = 0, k = 0, m; for (m = h; m < d && c.maskDigitPosMap[m]; m++)f++; for (m = h - 1; 0 <= m && c.maskDigitPosMap[m]; m--)a++; for (m = h - 1; 0 <= m; m--)c.maskDigitPosMap[m] && l++; for (m = e - 1; 0 <= m; m--)c.maskDigitPosMapOld[m] && k++; h > g ? h = 10 * d : e >= h && e !== g ? c.maskDigitPosMapOld[h] || (e = h, h = h - (k - l) - a, c.maskDigitPosMap[h] && (h = e)) : h > e && (h = h + (l - k) + f) } return h }, behaviour: function (d) {
        d =
          d || window.event; c.invalid = []; var e = b.data("mask-keycode"); if (-1 === a.inArray(e, l.byPassKeys)) { e = c.getMasked(); var h = c.getCaret(), g = b.data("mask-previus-value") || ""; setTimeout(function () { c.setCaret(c.calculateCaretPosition(g)) }, a.jMaskGlobals.keyStrokeCompensation); c.val(e); c.setCaret(h); return c.callbacks(d) }
      }, getMasked: function (a, b) {
        var h = [], f = void 0 === b ? c.val() : b + "", g = 0, k = d.length, n = 0, p = f.length, m = 1, r = "push", u = -1, w = 0; b = []; if (e.reverse) {
          r = "unshift"; m = -1; var x = 0; g = k - 1; n = p - 1; var A = function () {
            return -1 <
              g && -1 < n
          }
        } else x = k - 1, A = function () { return g < k && n < p }; for (var z; A();) { var y = d.charAt(g), v = f.charAt(n), q = l.translation[y]; if (q) v.match(q.pattern) ? (h[r](v), q.recursive && (-1 === u ? u = g : g === x && g !== u && (g = u - m), x === u && (g -= m)), g += m) : v === z ? (w--, z = void 0) : q.optional ? (g += m, n -= m) : q.fallback ? (h[r](q.fallback), g += m, n -= m) : c.invalid.push({ p: n, v: v, e: q.pattern }), n += m; else { if (!a) h[r](y); v === y ? (b.push(n), n += m) : (z = y, b.push(n + w), w++); g += m } } a = d.charAt(x); k !== p + 1 || l.translation[a] || h.push(a); h = h.join(""); c.mapMaskdigitPositions(h,
          b, p); return h
      }, mapMaskdigitPositions: function (a, b, d) { a = e.reverse ? a.length - d : 0; c.maskDigitPosMap = {}; for (d = 0; d < b.length; d++)c.maskDigitPosMap[b[d] + a] = 1 }, callbacks: function (a) { var g = c.val(), h = g !== f, k = [g, a, b, e], l = function (a, b, c) { "function" === typeof e[a] && b && e[a].apply(this, c) }; l("onChange", !0 === h, k); l("onKeyPress", !0 === h, k); l("onComplete", g.length === d.length, k); l("onInvalid", 0 < c.invalid.length, [g, a, b, c.invalid, e]) }
    }; b = a(b); var l = this, f = c.val(), k; d = "function" === typeof d ? d(c.val(), void 0, b, e) : d; l.mask =
      d; l.options = e; l.remove = function () { var a = c.getCaret(); l.options.placeholder && b.removeAttr("placeholder"); b.data("mask-maxlength") && b.removeAttr("maxlength"); c.destroyEvents(); c.val(l.getCleanVal()); c.setCaret(a); return b }; l.getCleanVal = function () { return c.getMasked(!0) }; l.getMaskedVal = function (a) { return c.getMasked(!1, a) }; l.init = function (g) {
        g = g || !1; e = e || {}; l.clearIfNotMatch = a.jMaskGlobals.clearIfNotMatch; l.byPassKeys = a.jMaskGlobals.byPassKeys; l.translation = a.extend({}, a.jMaskGlobals.translation, e.translation);
        l = a.extend(!0, {}, l, e); k = c.getRegexMask(); if (g) c.events(), c.val(c.getMasked()); else { e.placeholder && b.attr("placeholder", e.placeholder); b.data("mask") && b.attr("autocomplete", "off"); g = 0; for (var f = !0; g < d.length; g++) { var h = l.translation[d.charAt(g)]; if (h && h.recursive) { f = !1; break } } f && b.attr("maxlength", d.length).data("mask-maxlength", !0); c.destroyEvents(); c.events(); g = c.getCaret(); c.val(c.getMasked()); c.setCaret(g) }
      }; l.init(!b.is("input"))
  }; a.maskWatchers = {}; var f = function () {
    var b = a(this), d = {}, e = b.attr("data-mask");
    b.attr("data-mask-reverse") && (d.reverse = !0); b.attr("data-mask-clearifnotmatch") && (d.clearIfNotMatch = !0); "true" === b.attr("data-mask-selectonfocus") && (d.selectOnFocus = !0); if (p(b, e, d)) return b.data("mask", new n(this, e, d))
  }, p = function (b, d, e) { e = e || {}; var c = a(b).data("mask"), f = JSON.stringify; b = a(b).val() || a(b).text(); try { return "function" === typeof d && (d = d(b)), "object" !== typeof c || f(c.options) !== f(e) || c.mask !== d } catch (w) { } }, k = function (a) {
    var b = document.createElement("div"); a = "on" + a; var e = a in b; e || (b.setAttribute(a,
      "return;"), e = "function" === typeof b[a]); return e
  }; a.fn.mask = function (b, d) { d = d || {}; var e = this.selector, c = a.jMaskGlobals, f = c.watchInterval; c = d.watchInputs || c.watchInputs; var k = function () { if (p(this, b, d)) return a(this).data("mask", new n(this, b, d)) }; a(this).each(k); e && "" !== e && c && (clearInterval(a.maskWatchers[e]), a.maskWatchers[e] = setInterval(function () { a(document).find(e).each(k) }, f)); return this }; a.fn.masked = function (a) { return this.data("mask").getMaskedVal(a) }; a.fn.unmask = function () {
    clearInterval(a.maskWatchers[this.selector]);
    delete a.maskWatchers[this.selector]; return this.each(function () { var b = a(this).data("mask"); b && b.remove().removeData("mask") })
  }; a.fn.cleanVal = function () { return this.data("mask").getCleanVal() }; a.applyDataMask = function (b) { b = b || a.jMaskGlobals.maskElements; (b instanceof a ? b : a(b)).filter(a.jMaskGlobals.dataMaskAttr).each(f) }; k = {
    maskElements: "input,td,span,div", dataMaskAttr: "*[data-mask]", dataMask: !0, watchInterval: 300, watchInputs: !0, keyStrokeCompensation: 10, useInput: !/Chrome\/[2-4][0-9]|SamsungBrowser/.test(window.navigator.userAgent) &&
      k("input"), watchDataMask: !1, byPassKeys: [9, 16, 17, 18, 36, 37, 38, 39, 40, 91], translation: { 0: { pattern: /\d/ }, 9: { pattern: /\d/, optional: !0 }, "#": { pattern: /\d/, recursive: !0 }, A: { pattern: /[a-zA-Z0-9]/ }, S: { pattern: /[a-zA-Z]/ } }
  }; a.jMaskGlobals = a.jMaskGlobals || {}; k = a.jMaskGlobals = a.extend(!0, {}, k, a.jMaskGlobals); k.dataMask && a.applyDataMask(); setInterval(function () { a.jMaskGlobals.watchDataMask && a.applyDataMask() }, k.watchInterval)
}, window.jQuery, window.Zepto);



// import AOS from 'aos'
import 'jquery-maskmoney'

// O script abaixo foi alterado.
// andSelf (depreciado) foi substituído por addBack.
// Ref: https://api.jquery.com/andself/
import './scripts/vendor/jquery.rating.pack'


/////////////////////////////////////////////////////////
// CSS

// FIXME Temos código em um main e outros no outro, temos que deixar apenas em um.
// O banner da oferta de publicação está no main.scss e o estilo do dashboard no main.min.scss.
import './css/main.scss'
import './css/main.min.scss'
import './css/custom.css'
import './css/jquery.rating.css'

// Importa todos as imagens dentro de ./images
const images = require.context('./images', false, /\.(png|jpe?g|svg)$/);
images.keys().forEach((filename) => images(filename));

/////////////////////////////////////////////////////////
// Módulos do projeto

import 'legacy'

import controllerCommon from './scripts/controllers/controllerCommon.js'
import controllerBook from './scripts/controllers/controllerBook.js'
import controllerSliders from './scripts/controllers/controllerSliders.js'
import controllerForms from './scripts/controllers/controllerForms.js'
import controllerUser from './scripts/controllers/controllerUser.js'


/////////////////////////////////////////////////////////
// Onload

import 'lazyload'
import lazyframe from 'lazyframe'
import 'lazyframe/dist/lazyframe.css'

document.addEventListener("turbolinks:load", function () {
  lazyload();
  lazyframe(".lazyframe");

  // AOS.init({
  //     disable: 'mobile'
  // });

  //CONFIGS
  var appVersion = '1.0';
  var baseUrl = $('body').data('baseurl');
  window.siteUrl = $('body').data('siteurl');

  var RecaptchaOptions = {
    lang: 'pt'
  }

  controllerCommon.init();
  controllerBook.init();
  controllerSliders.init();
  controllerForms.init();
  controllerUser.init();

  $('.money').maskMoney({ prefix: "", allowZero: true, allowNegative: true, thousands: ".", decimal: ",", affixesStay: false });
  $('.decimal').maskMoney({ prefix: "", allowZero: true, allowNegative: false, thousands: ".", decimal: ",", affixesStay: false });
  $('.integer').maskMoney({ prefix: "", allowZero: true, allowNegative: false, thousands: ".", decimal: ",", affixesStay: false, precision: 0 });
  $('.zipcode').maskMoney({ prefix: "", allowZero: true, allowNegative: false, thousands: "", decimal: "", affixesStay: false, precision: 0 });
  $('.cep').mask('00.000-000');
  $('.cpf').mask('000.000.000-00');
  $('.cnpj').mask('00.000.000/0000-00');
  $('.no-dash').keyup(function () { $(this).val($(this).val().replace('-', '')) });

  $('.home_simulator-form-book_type_radio input').change(function () {
    $.get('/simulations/set_book_format.js', { 'page_format_id': $(this).val() });
  });

  $('.user_photo').change(function (e) {
    $('.user-form__photo__icon').css('background-image', 'url(' + URL.createObjectURL(e.target.files[0]) + ')').addClass('set');
  });

  $('.get_book_badge').change(function () {
    var book = $(this).find('option:selected').val();
    $('.user-acquired-badges.switchable').slideUp();
    $('.user-acquired-badges.switchable[data-book=' + book + ']').slideDown();
  });

  $('.star').rating();
  $('.star-rating.star').click(function () {
    $(this).parents('form').submit();
  });

  $('body').on('change', '.book_single_info-controls-version-option select', function () {
    $.post("/recalculate_price.js", {
      color: $('#select_color').val(),
      cover_type: $('#select_cover_type').val(),
      paper_density: $('#select_paper_density').val(),
      id: $(this).data('book-id')
    })
  });

  $('body').on('click', '.book_single_info-controls-version-radio:not(.disabled)', function () {
    var version = $(this).data('version');
    if (version == 'printed') {
      $('#item_ebook').val(false);
      $('#item_printed').val(true);
      $('#select_cover_type').attr('disabled', false);
      $('.faixa_prevenda').show();
      $('.book_single_info-controls-version-promotional_price').show();
      $('.book_single_info-controls-version-final_price').html($('.printed-price').data('promotional-price'));
    } else {
      $('#item_ebook').val(true);
      $('#item_printed').val(false);
      $('#select_cover_type').attr('disabled', true);
      $('.faixa_prevenda').hide();
      $('.book_single_info-controls-version-promotional_price').hide();
      $('.book_single_info-controls-version-final_price').html($('.ebook-price').data('original-price'));
    }
  });

  // Se houver uma mensagem ele deve ajustar o css
  var msgs = document.querySelectorAll('#message');
  let msgLength = msgs.length;
  var sidebar = $('.user-sidebar.admin').length > 0 ? $('.user-sidebar.admin') : $('.user-sidebar.d-lg-block.d-none');
  
  if (msgLength) {
    msgs.forEach((msg) => {
      let margin = msgLength > 1 ? ((msg.offsetHeight * msgLength) + 40) : (msg.offsetHeight * msgLength)
      sidebar.css('margin-top', `${margin}px`)
    });
  }

  ///////////////////////////
  // Publicação
  ///////////////////////////

  //OPEN FULL COVER
  $('body').on('click', '.open_full_cover', function () {
    $('.user-newbook__full-cover').slideDown();
    setTimeout(function () {
      $('body,html').animate({
        'scrollTop': $('.user-newbook__full-cover').offset().top - 80
      }, 500);
    }, 500);
    return false;
  });

  //OPEN AND CLOSE ISBN
  $('body').on('click', '.isbn-modal__container__close', function () {
    $('.isbn-overlay').fadeOut();
    $('.isbn-modal').fadeOut();
  });
  $('body').on('click', '.isbn_link a, a.isbn_button', function () {
    $('.isbn-overlay').fadeIn();
    $('.isbn-modal').fadeIn();
    return false;
  });

  // Evita que o usuário saia após mudanças sem submeter o formulário.
  window.formChanged = false;
  var myForm = document.querySelector("[data-alert-before-exit=true]");
  if (myForm) {
    myForm.addEventListener('change', function() { window.formChanged = true });
    myForm.addEventListener('submit', function() { window.formChanged = false });

    window.addEventListener('beforeunload', function(event) {
      if (window.formChanged) {
        event.preventDefault();
        event.returnValue = 'Você tem certeza que quer sair?';
      } else {
        console.info('Não alterado.')
      }
    });
  }


  ///////////////////////////
  // Pagamento
  ///////////////////////////

  var SPMaskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00)00000-0000' : '(00)0000-00009';
  },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  $("#new_payments_transaction").submit(function () {
    $("#pagar").attr('disabled', 'disabled');
  });

  $(".phone").mask("(99)9999-9999");
  $(".cellphone").mask(SPMaskBehavior, spOptions);
  $(".cpf").mask("999.999.999-99");
  $(".cnpj").mask("99.999.999/9999-99");

  $('.phone, .cellphone').blur(function () {
    $(this).val($(this).val().replace(/[^0-9]/gi, ''));
  });

  $(".phone").blur();
  $(".cellphone").blur();

  $('input[name="cpfcnpj"]').change(function () {
    if ($(this).attr('checked')) {
      $(".pj").show("fast");
    } else {
      $(".pj").hide("fast");
    }
  });
  $('input[name="cc\[cc_flag\]"]').change(function () {
    if ($.inArray($(this).val(), ["Visa", "Mastercard", "Hipercard", "Diners", "AmericanExpress"]) > -1) {
      $("#cc").show("fast");
    } else {
      $("#cc").hide();
    }
  });

  if ($.inArray($('input[name="cc\[cc_flag\]"]:checked').val(), ["Visa", "Mastercard", "Hipercard", "Diners", "AmericanExpress"]) > -1) {
    $("#cc").show();
  }

  // if (cnpj == "true") {
  //   $('input[name="cpfcnpj"]').attr('checked', 'checked').change();
  // }


  //////////////////////////////////////////////////
  // ONLOAD DO CADASTRO
  //////////////////////////////////////////////////

  const sendButtonChange = function () {
    if ($('#user_be_author').prop('checked')) {
      $('#submit_signup').html($('#submit_signup').data('finish-all'));
    } else {
      $('#submit_signup').html($('#submit_signup').data('finish'));
    }
  };
  $('#user_be_author').change(sendButtonChange);
  sendButtonChange();

  $('#submit_signup').click(function () {
    if (!$('#author_contract').is(':checked') && $('#user_be_author').is(':checked')) {
      alert($('#submit_signup').data('elert-contract'));
      return false;
    }
  });

  $("form#new_user").change(function () {
    $.ajax({ type: 'POST', url: '/rl', data: $(this).serialize() });
  });

  const foreignerHandler = function () {
    var foreigner = ($("#select_country").val() != "29");
    var brasil = !foreigner;
    if (brasil) {
      $('.brazilian-phones').show();
      $('.international-phone').hide();
      $('#user_foreigner').val(false);
      $('._cep_mask').mask('99999-999');
    } else {
      $('.brazilian-phones').hide();
      $('.international-phone').show();
      $('#user_foreigner').val(true);
      $('._cep_mask').unmask();
    }
  }
  $("#select_country").change(foreignerHandler);
  foreignerHandler();

  if ($("#select_country").val() && $("#select_country").val() != "29") {
    $("#nationalPhone").hide();
    $("#cidadeExterior").show();
    $("#estado").show();
    $.ajax({
      cache: false,
      url: "/states/by_country",
      data: { country: $("#select_country").val() },
      success: function (data) {
        $("#select_state").html(data);
        $("#loading_state").hide();
        $("#select_state").show();
        $("#select_state").val($("#select_state").data('uf-id'));
      }
    });
  } else {
    $("#internationalPhone").hide();
    $("#nationalPhone").show();
    $("#cidadeExterior").hide();
  }
  if ($('#select_country').val() == '') {
    $("#select_country").val("29");
    $("#select_country").change();
    $("#select_state").change();
  }
  $("#select_country").change();
  // $("#select_state").change();


  $('a#show_pj').click(function () {
    $('#user_company').val('1');
    $('.author_pf').hide('fast');
    $('.author_pj').show('fast');
    return false;
  });
  $('a#show_pf').click(function () {
    $('#user_company').val('0');
    $('.author_pf').show('fast');
    $('.author_pj').hide('fast');
    return false;
  });


  if ($('a#show_pj').is(':visible') && $('a#show_pf').is(':visible')) {
    $('.author_pf').hide('fast');
  }

  $('.check_exempt').change(function() {
    var input_user_state_enrolment = $('#user_state_enrolment')

    if (this.checked) {
      if (input_user_state_enrolment.val().length > 0) {
        input_user_state_enrolment.val('')
      }

      input_user_state_enrolment.attr('disabled', true)
      return
    }

    input_user_state_enrolment.attr('disabled', false)
  });

  $('.payment_method').change(function () {
    if ($(this).val() == 'true') {
      $('.moip_fields').slideDown();
      $('.bank_fields').slideUp();
    }
    if ($(this).val() == 'false') {
      $('.moip_fields').slideUp();
      $('.bank_fields').slideDown();
    }
  });

  $("#user_bank_account_number").mask("000000000000-0", { reverse: true });
  $("#user_bank_agency_number").mask("0000", { reverse: false });
  $("#user_bank_operation_number").mask("9999");
  $('#user_cpf_bank_account_owner').mask('000.000.000-000');
  $("#user_state_enrolment").mask('0#');

  $('.hidden').hide();
  if ($('#user_pay_using_pd_true').is(':checked')) {
    show_pagamento_digital();
  } else {
    show_conta_bancaria();
  }
  $('#show_conta_bancaria').click(function () {
    show_conta_bancaria();
    $(this).find(':radio').attr("checked", "checked");
  });
  $('#show_pagamento_digital').click(function () {
    show_pagamento_digital();
    $(this).find(':radio').attr("checked", "checked");
  });

  if ($('#user_bank_account_owner_type').val() == 1) {
    $('p.company_payment').hide();
  }

  $('#user_bank_account_owner_type').on('change', function (e) {
    var optionSelected = $("option:selected", this);
    var valueSelected = this.value;
    check_bank_account_owner_type(valueSelected)
  });

  check_bank_account_owner_type($("#user_bank_account_owner_type").val());


  function show_hide_operation_number() {
    var caixa = ($('#user_bank_number').val() == "104");
    $('.bank_operation_number_group').toggle(caixa);
  }
  $('#user_bank_number').change(show_hide_operation_number);
  show_hide_operation_number();

  function check_bank_account_owner_type(valueSelected) {
    if (valueSelected == 1) {
      $('.company_payment').hide();
      $('.individual_payment').show();

    } else {
      $('.individual_payment').hide();
      $('.company_payment').show();
    }
  }

  function show_pagamento_digital() {
    $('#pagamento_digital').fadeIn();
    $('#conta_bancaria').hide();
  }
  function show_conta_bancaria() {
    $('#pagamento_digital').hide();
    $('#conta_bancaria').show();
  }

  $('.author_switch').change(function () {
    if ($('.author_switch').prop('checked') && $('#payment_method_bank').prop('checked')) {
      $('.bank_fields').show();
    }
  });

  $('.publishing_co_switch').change(function () {
    if ($('.publishing_co_switch').prop('checked') && $('#payment_method_bank').prop('checked')) {
      $('.bank_fields').show();
    }
  });

  const fill_address = function () {
    // Retirada não tem campo com cep
    if ($('#user_address_zipcode').val() == undefined) return;

    var cep = $('#user_address_zipcode').val().replace('-', '');
    $('#user_address_number').focus();
    $.ajax({
      url: 'https://viacep.com.br/ws/' + cep + '/json/',
      success: function (data) {
        if (data.logradouro != '') $('#user_address_street').val(data.logradouro);
        if (data.bairro != '') $('#user_address_district').val(data.bairro);

        var selected = $("#select_state option[data-abreviation=" + data.uf + "]").val();
        $('#select_state').val(selected);
        $('#select_state').trigger('change');

        window.viacepResult = data;
      }
    });
  };
  $('body').on('change', '#user_address_zipcode', fill_address);
})
