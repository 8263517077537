import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input';
import intlTelInputUtils from './intlTelInputUtils';

document.addEventListener("turbolinks:load", function () {
  const input = document.querySelector("#phone");
  if (input) {
    const it = intlTelInput(input, {
      // utilsScript:'https://intl-tel-input.com/node_modules/intl-tel-input/build/js/utils.js',
      // https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js
      utilsScript: intlTelInputUtils,
      hiddenInput: "international_phone",
      initialCountry: input.getAttribute('data-initial-country')
    });

    $('#phone').focusout(function () {
      if (!it.isValidNumber() && $(this).val() !== '') {
        $("#error_phone").remove();
        var errorMap = [
          $(this).data('invalid-number'),
          $(this).data('invalid-country-code'),
          $(this).data('number-too-short'),
          $(this).data('very-large-number'),
          $(this).data('invalid-number')
        ];
        var errorCode = it.getValidationError();
        var msg = errorMap[errorCode];
        $("#phone").parent().parent().append("<span id='error_" + "phone" + "' class='error' style='margin-bottom: 20px;'>" + msg + "</span>");
        // $("#phone").parent().parent().append("<span id='error_" + "phone" + "' class='error' style='margin-bottom: 20px;'>" + errorCode + "</span>");
      } else {
        $("#error_phone").remove();
      }
    });
  }

  function select_city() {
    const city = $("#select_city");
    city.val(city.data('city-id'));
  }
  function select_state() {
    const state = $("#select_state");
    state.val(state.data('uf-id'));
  }

  $("#select_country").change(function () {
    $.ajax({
      cache: false,
      url: "/states/by_country",
      data: { country: $(this).val() },
      success: function (data) {
        $("#select_state").html(data);
        $("#loading_state").hide();
        $("#select_state").show();
        select_state();
      }
    });
    if ($(this).val() == "29") {
      $("#cidadeExterior").hide();
      $("#tax_number").hide();
      $("#cpf").show();
      $("#internationalPhone").hide();
      $("#nationalPhone").show();
      $("#estado").show();
      $("#city_reload .btn").attr("disabled", "disabled");
      $("#user_foreigner").val("false");
      $("#district").show();
      $(".become-author").show();
    } else {
      $("#cidadeExterior").show();
      $("#district").hide();
      $("#city_reload .btn").removeAttr("disabled");
      $("#cpf").hide();
      $("#tax_number").show();
      $("#internationalPhone").show();
      $("#nationalPhone").hide();
      $("#user_foreigner").val("true");
      $("#select_state").val("");
      $("#cidade").hide();
      $(".become-author").hide();
    }

    const countries_with_state = ["BRASIL", "CANADÁ", "ARGENTINA", "Estados Unidos", 'ITÁLIA', 'AUSTRÁLIA', 'ESPANHA'];
    if ($.inArray($("#select_country option:selected").html(), countries_with_state) >= 0) {
      $(".col-state,#estado").show();
    } else {
      $(".col-state,#estado").hide();
    }
  }).keypress(function () { $(this).change(); });

  $("#select_state").change(function () {
    if ($(this).val() == "") {
      $("#cidade").hide();
      $("#select_city").val("");
    } else {
      if ($("#select_country").val() == "29") {
        $("#cidade").hide();
        $("#loading_city").show();
        $.ajax({
          cache: false,
          url: "/cities/by_uf",
          data: { uf: $(this).val() },
          success: function (data) {
            $("#select_city").html(data);
            $("#loading_city").hide();
            $("#cidade").show();
            select_city();

            if (window.viacepResult && window.viacepResult.localidade) {
              console.info(window.viacepResult);
              var city = $("#select_city option:contains('" + window.viacepResult.localidade + "')").val()
              $('#select_city').val(city);
            }
          }
        });
      } else {
        $("#cidadeExterior").show();
      }
    }
  }).keypress(function () { $(this).change(); });

  $("#city_reload #select_city").change(function () {
    if ($(this).val() != "") {
      $("#city_reload .btn").removeAttr("disabled");
    } else {
      $("#city_reload .btn").attr("disabled", "disabled");
    }
  }).keypress(function () { $(this).change(); });

  $(".refresh_user").submit(function () {
    $.fancybox.close;
    $.fancybox("<h3>Enviando dados, por favor aguarde!</h3>", { 'enableEscapeButton': false, 'showCloseButton': false, 'hideOnOverlayClick': false, 'hideOnContentClick': false });
  });

  displayAuthorTerms();

  $('#user_be_author').change(function () {
    displayAuthorTerms();
  });

  function displayAuthorTerms() {
    if ($('#user_be_author').is(':checked')) {
      $('#author-terms').show();
    } else {
      $('#author-terms').hide();
      $('.become-author').height(144.20);
    }
  }

  // Validação JS desabilitada.
  // validateForm();

  function onlyNumbers(element_id) {
    var element = document.getElementById(element_id);
    var number = /^[0-9]+$/;
    removeErrorMessage(element_id);
    if ((!element.value.match(number)) && (element.value != '')) {
      errorMessage(element_id, "Utilize apenas números, por favor");
    }
  }

  function verifyLength(element_id, length) {
    var element = document.getElementById(element_id);
    removeErrorMessage(element_id);
    if (!(element.value.length == length)) {
      errorMessage(element_id, "O campo deve ter " + length + " caracteres");
    }
  }

  function validIfNoSpecialCharacters(element_id) {
    var specialCharacters = /^[0-9a-zA-ZÀ-ÿ_ ]+$/;
    var element = document.getElementById(element_id);
    removeErrorMessage(element_id);
    if ((!element.value.match(specialCharacters)) && (element.value != '')) {
      errorMessage(element_id, "Não utilize os seguintes caracteres especiais, por favor. Ex.: < > = ; ° % $ # @ ¨ & *");
    }
  }

  function errorMessage(element_id, message) {
    $("#" + element_id).parent().append("<span id='error_" + element_id + "' class='error' style='margin-bottom: 20px;'>" + message + "</span>");
  }

  function isBlank(element_id) {
    var element = document.getElementById(element_id);
    removeErrorMessage(element_id);
    if (element.value == '') {
      errorMessage(element_id, "Campo não pode ficar em branco");
      return true;
    }
  }

  function removeErrorMessage(element_id) {
    $("#error_" + element_id).remove();
  }

  function validateForm() {
    $('#user_name').focusout(function () {
      if (!isBlank("user_name")) {
        validIfNoSpecialCharacters("user_name");
      }
    });
    $('#user_home_phone_number').focusout(function () {
      if (!isBlank("user_home_phone_number")) {
        onlyNumbers("user_home_phone_number");
      }
      verifyLength("user_home_phone_number", 8);
    });
    $('#user_home_phone_area_code').focusout(function () {
      if (!isBlank("user_home_phone_area_code")) {
        onlyNumbers("user_home_phone_area_code");
      }
      verifyLength("user_home_phone_area_code", 2);
    });
    $('#user_mobile_phone_number').focusout(function () {
      if (!isBlank("user_mobile_phone_number")) {
        onlyNumbers("user_mobile_phone_number");
      }
      verifyLength("user_mobile_phone_number", 9);
    });
    $('#user_mobile_phone_area_code').focusout(function () {
      if (!isBlank("user_mobile_phone_area_code")) {
        onlyNumbers("user_mobile_phone_area_code");
      }
      verifyLength("user_mobile_phone_area_code", 2);
    });
    // $('#user_cpf').focusout(function(){
    //   if(!isBlank("user_cpf")){
    //     onlyNumbers("user_cpf");
    //   }
    // });
    $('#user_address_zipcode').focusout(function () {
      if (!isBlank("user_address_zipcode")) {
        onlyNumbers("user_address_zipcode");
      }
    });
    $('#user_address_street').focusout(function () {
      if (!isBlank("user_address_street")) {
        validIfNoSpecialCharacters("user_address_street");
      }
    });
    $('#user_address_number').focusout(function () {
      if (!isBlank("user_address_number")) {
        onlyNumbers("user_address_number");
      }
    });
    $('#user_address_complement').focusout(function () {
      validIfNoSpecialCharacters("user_address_complement");
    });
    $('#user_address_district').focusout(function () {
      if (!isBlank("user_address_district")) {
        validIfNoSpecialCharacters("user_address_district");
      }
    });
    $('#user_cnpj').focusout(function () {
      if (!isBlank("user_cnpj")) {
        onlyNumbers("user_cnpj");
      }
    });
    $('#foreign_city_address').focusout(function () {
      if (!isBlank("user_foreign_city_address")) {
        validIfNoSpecialCharacters("user_foreign_city_address");
      }
    });
  }
});
